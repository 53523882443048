import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BHVideosModal from "../components/bh-videos-modal"
import useModalProps from "../helpers/use-modal-props"

import "../styles/bh-videos/bh-videos.style.css"

// Import Swiper React components
// import '/node_modules/swiper/css';
import "swiper/swiper.css"
// import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from "swiper/react"

const dataVideos = [
  {
    introduction: [
      {
        id: 0,
        src: "https://player.vimeo.com/video/833656626?h=a25c79a09d",
        title: "Introducing BH Digital - November 2021",
      },
      {
        id: 1,
        src: "https://player.vimeo.com/video/833659906?h=88073071ff",
        title: "Digital Asset Ecosystem - February 2022",
      },
    ],
    crypto_bytes: [
      {
        id: 14,
        title: "CeFi X DeFi",
        src:
          "https://player.vimeo.com/video/1020304244?badge=0&autopause=0&player_id=0&app_id=58479",
      },
      {
        id: 13,
        src:
          "https://player.vimeo.com/video/962590430?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "Founders Panel Discussion",
      },
      {
        id: 11,
        src:
          "https://player.vimeo.com/video/962650999?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "Venture Investing Presentation",
      },
      {
        id: 10,
        src:
          "https://player.vimeo.com/video/962652759?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "Liquid Trading and Investing",
      },
      {
        id: 9,
        src:
          "https://player.vimeo.com/video/948374711?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "BH Digital and SYKY",
      },
      {
        id: 8,
        src:
          "https://player.vimeo.com/video/937842003?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "BH Digital and DolarApp",
      },
      {
        id: 7,
        src:
          "https://player.vimeo.com/video/927651979?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "BTC and ETH - Rob Bogucki",
      },
      {
        id: 6,
        src:
          "https://player.vimeo.com/video/916663856?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
        title: "BH Digital and Alluvial",
      },
      {
        id: 5,
        src:
          "https://player.vimeo.com/video/902004027?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "It's all a game - Colleen Sullivan",
      },
      /* {
        id: 2,
        src: "https://player.vimeo.com/video/833663515?h=348922e770",
        title: "An Introduction with Colleen and Peter - June 2023",
      }, */
    ],
    focus_series: [
      {
        id: 13,
        src:
          "https://player.vimeo.com/video/962643958?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "Risk Management & Compliance Discussion",
      },
      {
        id: 12,
        src:
          "https://player.vimeo.com/video/962648923?badge=0&autopause=0&player_id=0&app_id=58479",
        title: "Spotlight with Former SEC Chairman Jay Clayton",
      },
      {
        id: 4,
        src: "https://player.vimeo.com/video/833664673?h=d95a633400",
        title: "Regulatory Landscape with Andrew Nelson - June 2023",
      },
      {
        id: 3,
        src: "https://player.vimeo.com/video/833667130?h=807f2ebf7a",
        title: "Counterparty Risk with Ivan Chen - March 2023",
      },
    ],
  },
]

var initialOpen = true

if (typeof window !== "undefined") {
  if (
    window.localStorage.length == 0 ||
    window.localStorage.getItem("bh-disclosureNoticesStatus") !== "accepted"
  ) {
    initialOpen = false
  }
}

/* function setInitialOpen(value){
  initialOpen = value;
} */

const VideosPageTemplate = ({
  data: {
    wpgraphql: {
      page: { title, newvideosacf },
    },
  },
}) => {
  /* console.log("initialOpen in template", initialOpen) */

  // console.log('checking passed swiper', my_swiper.slideNext());
  // const { modalProps } = useModalProps({ initialOpen: typeof window !== 'undefined' ? (window.localStorage.getItem('bh-disclosureNoticesStatus') !== 'accepted' ? false : true) : false });
  const { modalProps } = useModalProps({ initialOpen: initialOpen })
  const { modalStatus } = modalProps
  // console.log(newvideosacf,'Quick fix')

  // const swiper = useSwiper();
  // const [my_swiper, set_my_swiper] = null;

  const [swiper, setSwiper] = React.useState(null)
  const [swiperv2, setSecondSwiper] = React.useState(null)

  // var swiper = React.useState(null);

  const nexto = () => {
    swiper.slideNext()
  }
  const prevto = () => {
    swiper.slidePrev()
  }

  const nextov2 = () => {
    swiperv2.slideNext()
  }
  const prevtov2 = () => {
    swiperv2.slidePrev()
  }

  function swiperto(item) {
    alert(item)
    // swiper.slideTo(item);
  }

  const x = (x, y) => {
    return x * y
  }

  return (
    <Layout
      disableAgreementModal={true}
      onAccept={() => {
        modalProps?.openModal({})
      }}
      modalStateOverride={!modalStatus?.open}
    >
      <SEO title={title} />

      <div className="video-slider-wrapper v-intro-bg">
        <div className="vs-container-wrap">
          <div className="container">
            {/* <h2>
              <span>1</span>
              Brevan Howard Digital
            </h2> */}

            <h3 className="txt-white">
              <svg
                version="1.1"
                class="bh-svg-logo"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1867.7 79.9"
              >
                <g>
                  <path
                    class="st0"
                    d="M27.7,36.9c10.4-1.4,16.6-10.7,16.6-18.6c0-9.6-7.9-18.3-22-18.3H0c0.2,12.3,0,72.9,0,79.9h21.9
                  c14.4,0,28-7.1,28-23C49.9,42.8,39.9,37.5,27.7,36.9L27.7,36.9z M8.8,2.9c8.3,0,26-0.2,26,16.7c0,13.2-7.7,17.7-26,17.7V2.9z
                  M8.8,76.3V39.5c3.6,0,7.2-0.1,10.9-0.1c12.1,0,20.3,6.2,20.3,19C40,76.3,25,77.7,8.8,76.3z"
                  />
                  <path
                    class="st0"
                    d="M183.1,76.5L183,41.1c11.4-0.2,21-0.1,27.9,0.5v-4.4c-6.9,0.8-21.8,0.7-27.8,0.7c0,0.1,0-34.7,0-34.7
                  s27.1-0.1,32.8,0.6L214.4,0h-40.1v79.8h45.8l1.4-4C215.6,76.9,183.1,76.5,183.1,76.5L183.1,76.5z"
                  />
                  <path
                    class="st0"
                    d="M285,68.4v-1.4c0,0-18.8-45.4-26.5-66.9h-9.7c4.5,11.4,19.2,46.9,29.5,72.6c1.3,3.2,3.2,8.4,3.2,8.4h1.1
                  l33.5-81h-3.3L285,68.4z"
                  />
                  <path
                    class="st0"
                    d="M369.6-1.2h-1.1l-33.5,81h3.4l11.6-28h31.8c3.8,9.5,8,20.3,10.7,28h10.2C398,68.6,382.9,33,372.5,7.2
                  C371.2,4,369.6-1.2,369.6-1.2L369.6-1.2z M351.4,48.4l14.7-37v1.4c0,0,8.1,19.9,14.4,35.6L351.4,48.4L351.4,48.4z"
                  />
                  <path
                    class="st0"
                    d="M499.4,0h-3.8c0.2,12.3,0.2,58.9,0.2,65.9l-0.8-2c0,0-48.5-57.2-53.4-63.8h-5.1l0.7,6.1
                  c0.1,17.2,0,67.4,0,73.8h3.8V13.2l-0.8-2.6c0,0,50.8,61.3,59.3,70.7C498.9,58.5,499.4,22.8,499.4,0L499.4,0z"
                  />
                  <path
                    class="st0"
                    d="M644.5,37.9H602V0h-8.7c0.2,12.3-0.1,72.9-0.1,79.9h8.8c0-12.6-0.1-29.9-0.1-38.6h42.6c-0.1,14,0,34.8,0,38.6
                  h8.9V0h-8.9C644.6,5.2,644.5,26.9,644.5,37.9L644.5,37.9z"
                  />
                  <path
                    class="st0"
                    d="M732.1-0.8c-25.6,0-42.5,19.6-42.5,42c0,25.9,18.9,39.8,42.6,39.8s41.9-14.7,41.9-40.5
                  C774.1,18,757.6-0.8,732.1-0.8L732.1-0.8z M732,77.6c-18.3-0.1-32.3-15.4-32.3-36.4c0-18.1,10-38.3,32.3-38.3s32,19.5,32,37.6
                  C764,61.5,750.3,77.7,732,77.6L732,77.6z"
                  />
                  <path
                    class="st0"
                    d="M882.3,67.4V66L858.7-1.8h-1.1L833,67.2v-1.4c0,0-15.2-44.3-22.9-65.7h-9.4c4.3,11.8,16.1,46.9,26.5,72.6
                  c1.2,3.2,3,8.4,3,8.4h1.1l24.2-68.9c0,0.2,0,1.8,0.1,1.9c4.2,12,10.8,33.3,20.9,58.6c1.3,3.2,3.1,8.4,3.1,8.4h1l30.7-81h-3.7
                  L882.3,67.4L882.3,67.4z"
                  />
                  <path
                    class="st0"
                    d="M964.7-1.2h-1.1l-33.5,81h3.4l11.6-28h31.8c3.8,9.5,7.9,20.3,10.7,28H998C993.1,68.6,978,33,967.7,7.2
                  C966.4,4,964.7-1.2,964.7-1.2L964.7-1.2z M946.6,48.4l14.7-37v1.4c0,0,8.1,19.9,14.4,35.6L946.6,48.4L946.6,48.4z"
                  />
                  <path
                    class="st0"
                    d="M1160.6,0.7c-4.5-0.6-10.8-0.7-17.3-0.7h-19.8c0.2,12.3,0,72.8,0,79.8h9.4c5.2,0,18.4,0.2,25.5-0.6
                  c9.1-0.9,35.5-6.9,35.5-40.9C1193.9,12.1,1171.3,2.1,1160.6,0.7L1160.6,0.7z M1184.8,42c-0.8,22.2-14.5,28.8-21.9,31.8
                  c-8.1,3.3-28,2.2-30.3,1.4c0-22.8-0.1-48.6-0.1-71.4c4.9,0,18.5-0.2,25,1.3C1174.5,8.9,1185.4,25.6,1184.8,42L1184.8,42z"
                  />
                  <path
                    class="st1"
                    d="M1324,0.6c-4.8-0.7-9.6-0.7-14.5-0.7H1284c0,26.7,0.1,53.3,0.1,80h21.8c5.2,0,10.6,0,15.9-0.6
                  c22.1-2.4,36.8-18.8,36.8-40.9C1358.7,19,1342.6,3.3,1324,0.6L1324,0.6z M1325.6,70.2c-5,1.9-11.3,1.9-16.6,1.9
                  c-3.3,0-6.6,0-10-0.4V7.6h8.9c4.3,0,8.5,0.1,12.7,1.1c14.9,3.6,21.9,17.6,21.9,32C1342.5,54.1,1339.3,65.1,1325.6,70.2z"
                  />
                  <path
                    class="st1"
                    d="M1396.7-0.1c0.2,8.5,0.2,17.1,0.2,25.6c0,18.1-0.2,36.2-0.4,54.3h14.9c0-26.7-0.1-53.3-0.1-80H1396.7z"
                  />
                  <path
                    class="st1"
                    d="M1494.6,6.1c10.8,0,19.8,4.8,24.4,14.6l4.5-11.9c-9.6-7-18.6-10.3-30.6-10.3c-24,0-42.2,18-42.2,42
                  s19.3,40.8,42.7,40.8c8.9,0,21.9-2.6,30-6.6V42.8H1510v27.5c-3.7,2.6-9.6,3.8-14,3.8c-19.9,0-29.5-15.1-29.5-33.7
                  C1466.5,23.1,1475.3,6.1,1494.6,6.1L1494.6,6.1z"
                  />
                  <path
                    class="st1"
                    d="M1566.4-0.1c0.2,8.5,0.2,17.1,0.2,25.6c0,18.1-0.2,36.2-0.4,54.3h14.9c0-26.7-0.1-53.3-0.1-80H1566.4z"
                  />
                  <path
                    class="st1"
                    d="M1619.8-0.1L1618,7h27.1v72.9h14.7c0-24.3,0.1-48.5,0.1-72.9h27.9l-0.1-7.1L1619.8-0.1L1619.8-0.1z"
                  />
                  <path
                    class="st1"
                    d="M1745.2-0.2h-5l-34.7,80.1h8.7l11-25.6h27.4c3.6,8.5,7.1,17,10.2,25.6h16.7c-6.9-15.4-13.8-31-20.4-46.6
                  C1754.4,22.2,1749.2,11.3,1745.2-0.2L1745.2-0.2z M1728.6,45.9l10.2-24.9l10.4,24.9H1728.6L1728.6,45.9z"
                  />
                  <path
                    class="st1"
                    d="M1844.2,72.7h-12.8V-0.1h-14.9v80h48.3l3-8.4C1860,72.9,1851.9,72.7,1844.2,72.7L1844.2,72.7z"
                  />
                  <path
                    class="st0"
                    d="M132.3,21.3c0-10.6-8.5-21.1-26.9-21.1H87.9C88,4.1,88,12.6,88,22.8v4c0,21.8-0.1,48.8-0.1,53.3h9V44.6
                  c0,0.1,2.7,0.2,2.9,0.2c0.9,0,1.9,0.1,2.8,0.1c0.9,0,1.7-0.1,2.6-0.1c0.5,0,1.1-0.1,1.6-0.2c0.3,0,0.6-0.1,0.8-0.2
                  c0.4-0.1,0.7-0.1,0.7-0.1s17.7,30.1,21.1,35.8h10.4c-8.3-12.9-17.8-27.6-22.9-37.3C126.9,39.7,132.3,32.2,132.3,21.3L132.3,21.3z
                  M96.9,42.1V4.4l0.6-0.7c18.5-1.4,26.3,8.7,26.3,18.5c0,7.2-3.6,13.8-9.9,17.5C109.6,41.4,103.9,42.1,96.9,42.1L96.9,42.1z"
                  />
                </g>
                <path
                  class="st0"
                  d="M1079.1,21.3c0-10.6-8.5-21.1-26.9-21.1h-17.4c0.1,3.9,0.1,12.4,0.1,22.6v4c0,21.8-0.1,48.8-0.1,53.3h9V44.6
                c0,0.1,2.7,0.2,2.9,0.2c0.9,0,1.9,0.1,2.8,0.1c0.8,0,1.7-0.1,2.6-0.1c0.5,0,1.1-0.1,1.6-0.2c0.3,0,0.6-0.1,0.8-0.2
                c0.3-0.1,0.7-0.1,0.7-0.1s17.7,30.1,21.1,35.8h10.4c-8.3-12.9-17.8-27.6-22.9-37.3C1073.7,39.7,1079.1,32.2,1079.1,21.3L1079.1,21.3
                z M1043.8,42.1V4.4l0.6-0.7c18.5-1.4,26.3,8.7,26.3,18.5c0,7.2-3.6,13.8-9.9,17.5C1056.4,41.4,1050.7,42.1,1043.8,42.1z"
                />
              </svg>
              <br />
              Crypto Content
            </h3>
            {/* <h3 className="txt-white"><span className="txt-aqua">Brevan Howard Digital</span><br/>Crypto Content</h3> */}
            {/* <p className="s-sub-heading txt-center mb-0">Snackable sessions with Brevan Howard Digital</p> */}
            {/* <div id="swiper-intro" className="swiper-container">
              <Swiper
                // modules={[Navigation, Pagination]} 
                spaceBetween={50}
                slidesPerView={2}
                centeredSlides={true}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => {
                  console.log('checking swiper slider', swiper);
                  // my_swiper = swiper;
                  setSwiper(swiper);
                }}
                breakpoints={
                  {
                    320: {
                      slidesPerView:1
                    },
                    850: {
                      slidesPerView:2
                    }
                  }
                }
              >
                {dataVideos[0].introduction.map((video, index) => (
                  <SwiperSlide key={'intro-'+index}>
                    <p className="txt-white txt-center"><em>{video.title}</em></p>
                    <div className="iframe-container">
                      <iframe className="vimeo-iframe" src={video.src}  width="740" height="460" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </SwiperSlide>
                ))}
                
              </Swiper>   
            </div>              

            <div className="v-slide-nav"> 
              <button onClick={prevto}>
                <svg xmlns="http://www.w3.org/2000/svg" width="7.882" height="14.622" viewBox="0 0 7.882 14.622">
                  <path id="Path_73795" data-name="Path 73795" d="M0,0,6.794,6.794C6.443,6.443,13.589,0,13.589,0" transform="translate(7.325 0.53) rotate(90)" fill="none" stroke="#bbb4a9" strokeWidth="1.5"/>
                </svg>
              </button>
              <div className="vs-pagination">
                
                {dataVideos[0].introduction.map((pagi, index) => (
                  <span key={'nav-intro-'+index} onClick={() => swiper.slideTo(index)}></span>
                ))}                  
              </div>                
              <button onClick={nexto}>
                <svg xmlns="http://www.w3.org/2000/svg" width="7.882" height="14.622" viewBox="0 0 7.882 14.622">
                  <path id="Path_68716" data-name="Path 68716" d="M0,6.795,6.795,0C6.443.352,13.59,6.795,13.59,6.795" transform="translate(7.352 0.53) rotate(90)" fill="none" stroke="#bbb4a9" strokeWidth="1.5"/>
                </svg>
              </button> 
            </div> */}
          </div>
        </div>
      </div>

      <div className="video-slider-wrapper v-lighter-blue-bg pt-0 v-crypto-bg">
        <div className="vs-container-wrap">
          <div className="container">
            {/* <h2>
                <span>2</span>
                Snackable Sessions with BH Digital
              </h2> */}

            <h3 className="txt-white">Crypto Bytes</h3>

            <p className="s-sub-heading txt-center">
              Crypto Bytes is an investment-focused series on non-speculative
              use cases and real-world adoption.
            </p>

            {/* <p className="txt-white txt-center">
              <em>{dataVideos[0].crypto_bytes[0].title}</em>
            </p>
            <div className="single-video-container">
              <div className="iframe-container">
                <iframe
                  className="vimeo-iframe responsive-iframe"
                  src={dataVideos[0].crypto_bytes[0].src}
                  width="740"
                  height="460"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div> */}
            <div id="swiper-series" className="swiper-container">
              <Swiper
                // modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={2}
                centeredSlides={true}
                onSlideChange={() => console.log("slide change")}
                onSwiper={swiper => {
                  console.log("checking swiper slider", swiper)
                  // my_swiper = swiper;
                  setSwiper(swiper)
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  850: {
                    slidesPerView: 2,
                  },
                }}
              >
                {dataVideos[0].crypto_bytes.map((video, index) => (
                  <SwiperSlide key={"ser-" + index}>
                    <p className="txt-center">
                      <em>{video.title}</em>
                    </p>
                    <div className="iframe-container">
                      <iframe
                        className="vimeo-iframe responsive-iframe"
                        src={video.src}
                        width="740"
                        height="460"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="v-slide-nav">
              <button onClick={prevto}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_73795"
                    data-name="Path 73795"
                    d="M0,0,6.794,6.794C6.443,6.443,13.589,0,13.589,0"
                    transform="translate(7.325 0.53) rotate(90)"
                    fill="none"
                    stroke="#bbb4a9"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
              <div className="vs-pagination">
                {dataVideos[0].crypto_bytes.map((pagi2, index) => (
                  <span
                    key={"crypt-series-" + index}
                    onClick={() => swiperv2.slideTo(index)}
                  ></span>
                ))}
              </div>
              <button onClick={nexto}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_68716"
                    data-name="Path 68716"
                    d="M0,6.795,6.795,0C6.443.352,13.59,6.795,13.59,6.795"
                    transform="translate(7.352 0.53) rotate(90)"
                    fill="none"
                    stroke="#bbb4a9"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="video-slider-wrapper v-white-bg pt-0">
        <div className="vs-container-wrap">
          <div className="container">
            {/* <h2>
                <span>3</span>
                Brevan Howard Digital
              </h2> */}

            <h3 className="">Under the Hood</h3>
            <p className="s-sub-heading txt-center">
              Under the Hood is a business-focused series featuring topical
              deep-dives on risk, operations and regulatory matters.
            </p>

            <div id="swiper-series" className="swiper-container">
              <Swiper
                // modules={[Navigation, Pagination]}
                spaceBetween={50}
                slidesPerView={2}
                centeredSlides={true}
                onSlideChange={() => console.log("slide change")}
                onSwiper={swiper => {
                  console.log("checking swiper slider", swiper)
                  // my_swiper = swiper;
                  setSecondSwiper(swiper)
                }}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  850: {
                    slidesPerView: 2,
                  },
                }}
              >
                {dataVideos[0].focus_series.map((video, index) => (
                  <SwiperSlide key={"ser-" + index}>
                    <p className="txt-center">
                      <em>{video.title}</em>
                    </p>
                    <div className="iframe-container">
                      <iframe
                        className="vimeo-iframe responsive-iframe"
                        src={video.src}
                        width="740"
                        height="460"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            <div className="v-slide-nav">
              <button onClick={prevtov2}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_73795"
                    data-name="Path 73795"
                    d="M0,0,6.794,6.794C6.443,6.443,13.589,0,13.589,0"
                    transform="translate(7.325 0.53) rotate(90)"
                    fill="none"
                    stroke="#bbb4a9"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
              <div className="vs-pagination">
                {dataVideos[0].focus_series.map((pagi2, index) => (
                  <span
                    key={"nav-series-" + index}
                    onClick={() => swiperv2.slideTo(index)}
                  ></span>
                ))}
              </div>
              <button onClick={nextov2}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="7.882"
                  height="14.622"
                  viewBox="0 0 7.882 14.622"
                >
                  <path
                    id="Path_68716"
                    data-name="Path 68716"
                    d="M0,6.795,6.795,0C6.443.352,13.59,6.795,13.59,6.795"
                    transform="translate(7.352 0.53) rotate(90)"
                    fill="none"
                    stroke="#bbb4a9"
                    strokeWidth="1.5"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <BHVideosModal modalProps={modalProps} digitalassetsacf={newvideosacf} />
    </Layout>
  )
}

export default VideosPageTemplate

export const nvTemplateQuery = graphql`
  query newVideosPagesQuery($id: ID!) {
    wpgraphql {
      page(id: $id) {
        title
        newvideosacf {
          modalFooterText
          modalCloseButtonLabel
          modalAcceptButtonLabel
          disclosuresModalData {
            title
            content {
              text
              title
            }
          }
          riskFactorsModalData {
            title
            content {
              text
              title
            }
          }
        }
      }
    }
  }
`
